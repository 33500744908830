import useFormat from "@lib/hooks/use-format";
import {
  endTimeAtom,
  newVsReturningAtom,
  startTimeAtom,
  dashboardModeAtom,
} from "atoms";
import {
  AXIS_VALUES,
  chartTooltipStyles,
  METRICS_LABELS,
  FINE_GRAINED_AXIS_VALUES,
  GENERAL_METRICS_LABELS,
} from "constants/constants";
import dayjs from "dayjs";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const calculateRoundFactor = (maxValue: number) => {
  const numberCount = `${maxValue}`.split(".")[0].length;
  const factor = Math.max(0, numberCount - 1);
  const base =
    maxValue > 40000 ? 10 : maxValue > 10000 ? 8 : maxValue > 100 ? 5 : 2;
  const roundFactor = Math.pow(base, factor);
  return roundFactor;
};

export const getNearestAxisValue = (maxValue: number) => {
  let closest = AXIS_VALUES.filter((el) => el > maxValue).reduce(function (
    prev,
    curr
  ) {
    return Math.abs(curr - maxValue) < Math.abs(prev - maxValue) ? curr : prev;
  }, 1);
  if (closest < maxValue * 1.25 || closest > maxValue * 1.4) {
    closest = FINE_GRAINED_AXIS_VALUES.filter(
      (el) => el > maxValue * 1.25
    ).reduce(function (prev, curr) {
      return Math.abs(curr - maxValue) < Math.abs(prev - maxValue)
        ? curr
        : prev;
    }, 0);
  }
  return Math.max(1, closest);
};

const getChartData = ({
  dailyChannelData,
  comparedTimerange,
}: {
  dailyChannelData: {
    [date: string]: MarketingChannelOverviewInterface;
  };
  comparedTimerange?: number;
}) => {
  const currentChartData: { [key: string]: any }[] = [];
  let currentMaxValue = 0;
  let currentMaxRoasValue = 0;
  Object.entries(dailyChannelData).map(([date, value]) => {
    const valueData: { [key: string]: any } = {};
    if (comparedTimerange) {
      valueData["date"] = dayjs(date)
        .add(comparedTimerange, "day")
        .format("YYYY-MM-DD");
    } else {
      valueData["date"] = date;
    }
    let totalPurchaseAmount = 0;
    let totalSpendAmount = 0;
    totalPurchaseAmount += value.purchaseAmount;
    if (value.spend > 0) {
      totalSpendAmount += value.spend;
    }
    const roas =
      totalPurchaseAmount && totalSpendAmount && totalSpendAmount > 0.5
        ? totalPurchaseAmount / totalSpendAmount
        : 0;

    valueData["purchaseAmount"] = totalPurchaseAmount;
    valueData["spend"] = totalSpendAmount;

    valueData["roas"] = roas;
    if (Math.max(totalPurchaseAmount, totalSpendAmount) > currentMaxValue)
      currentMaxValue = Math.max(totalPurchaseAmount, totalSpendAmount);
    if (roas > currentMaxRoasValue)
      roas < 250 ? (currentMaxRoasValue = roas) : null;
    currentChartData.push(valueData);
  });
  return { currentChartData, currentMaxValue, currentMaxRoasValue };
};

type Props = {
  dailyChannelData: {
    [date: string]: MarketingChannelOverviewInterface;
  };
  dailyComparedChannelData?: {
    [date: string]: MarketingChannelOverviewInterface;
  };
  channel: string;
  suffix: string;
  onValueConversion: (
    value: number | undefined,
    numDigits: number,
    symbol?: boolean
  ) => string;
};

/**
 * Component holding the revenue chart info.
 *
 * @param {Props} props
 * @return {CustomElement}
 */
export function ConversionValueChart({
  dailyChannelData,
  dailyComparedChannelData,
  channel,
  suffix,
  onValueConversion,
}: Props) {
  const dataKeys = ["purchaseAmount", "roas", "spend"];
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const [chartData, setChartData] = useState<{ [key: string]: any }[]>([]);
  const colors: { [key: string]: string } = {
    roas: "#DAA702",
    purchaseAmount: "#6254FF",
    spend:
      channel === "google"
        ? "#DB4437"
        : channel === "tiktok"
          ? "#25F4EE"
          : channel === "pinterest"
            ? "#E60023"
            : "#0668E1",
  };
  const [maxValue, setMaxValue] = useState(0);
  const [maxRoasValue, setMaxRoasValue] = useState(0);
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);
  const compared = Boolean(Object.keys(dailyComparedChannelData ?? {}).length);
  const minDateFromComparedDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyComparedChannelData ?? {}).map((el) =>
          dayjs(el).unix()
        )
      ),
    [dailyComparedChannelData]
  );
  const minDateFromDaily = useMemo(
    () =>
      Math.min(...Object.keys(dailyChannelData).map((el) => dayjs(el).unix())),
    [dailyChannelData]
  );
  const timerange = useMemo(
    () =>
      dayjs(minDateFromDaily * 1000).diff(
        dayjs(minDateFromComparedDaily * 1000),
        "day"
      ),
    [minDateFromDaily, minDateFromComparedDaily]
  );
  const [newVsReturning] = useAtom(newVsReturningAtom);

  const { formatNumber } = useFormat();
  useEffect(() => {
    setChartData([]);
    setMaxValue(0);
    setMaxRoasValue(0);
    if (Object.values(dailyChannelData).length > 0) {
      const chartData = getChartData({ dailyChannelData });
      const { currentChartData, currentMaxValue, currentMaxRoasValue } =
        chartData;
      let comparedChartData: typeof chartData | undefined;
      if (compared && dailyComparedChannelData) {
        comparedChartData = getChartData({
          dailyChannelData: dailyComparedChannelData,
          comparedTimerange: timerange,
        });
        for (const value of comparedChartData.currentChartData) {
          const indexInCurrent = currentChartData.findIndex(
            (el) => el.date === value.date
          );
          if (indexInCurrent !== -1) {
            for (const key of Object.keys(value)) {
              if (key === "date") continue;
              currentChartData[indexInCurrent][`compared_${key}`] = value[key];
            }
          }
        }
      }
      setChartData(currentChartData);
      setMaxValue(
        compared
          ? Math.max(comparedChartData?.currentMaxValue ?? 0, currentMaxValue)
          : currentMaxValue
      );
      setMaxRoasValue(
        compared
          ? Math.max(
              comparedChartData?.currentMaxRoasValue ?? 0,
              currentMaxRoasValue
            )
          : currentMaxRoasValue
      );
    }
  }, [compared, dailyChannelData, dailyComparedChannelData, timerange]);
  const maxRoasDomain = getNearestAxisValue(maxRoasValue);
  const maxYDomain = getNearestAxisValue(maxValue);

  const transformLabel = (label: string, generalDashboardMode: boolean) => {
    const transformConfig: {
      [key: string]: string;
    } = {
      roas: "Aquisition ROAS",
    };

    if (newVsReturning === "new" && transformConfig[label]) {
      return transformConfig[label];
    }

    return generalDashboardMode
      ? GENERAL_METRICS_LABELS[label]
      : METRICS_LABELS[label];
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={chartData}
        margin={{
          top: 20,
          // right: 80,
          bottom: 20,
          left: 40,
        }}
      >
        <YAxis
          tickFormatter={(value: number) =>
            `${onValueConversion(value, 0, false)}`
          }
          tickSize={0}
          allowDataOverflow
          yAxisId="1"
          tickMargin={10}
          axisLine={false}
          tickCount={6}
          className="relative"
          domain={[0, maxYDomain]}
        >
          <Label
            value={`Spend | ${
              dashboardMode === "general" ? "Conv. Value" : "Revenue"
            } in ${suffix}`}
            offset={20}
            angle={-90}
            textAnchor="middle"
            position={{
              x: -20,
              y: 130,
            }}
          />
        </YAxis>
        <YAxis
          orientation="right"
          yAxisId="2"
          tickMargin={10}
          // domain={[0, 5]}
          axisLine={false}
          tickCount={maxRoasDomain % 5 === 0 ? 6 : 2}
          domain={[0, maxRoasDomain]}
        >
          <Label
            value={"ROAS"}
            angle={-90}
            position={"right"}
            offset={-15}
            textAnchor={"middle"}
          />
        </YAxis>
        <XAxis
          dataKey="date"
          tickSize={0}
          tickMargin={20}
          axisLine={false}
          tickFormatter={(value: string) =>
            value.split("-").slice(1).reverse().join("/")
          }
          domain={[
            dayjs(startTime).format("YYYY-MM-DD"),
            dayjs(endTime).format("YYYY-MM-DD"),
          ]}
        />
        <CartesianGrid stroke="var(--chart-grid)" vertical={false} />
        <Tooltip
          contentStyle={chartTooltipStyles}
          formatter={(value: string, name: string) => {
            let prefix = "";
            if (name.includes("compared_")) {
              prefix = "Previous ";
              name = name.replace("compared_", "");
            }
            return [
              name.includes("roas") === false
                ? `${onValueConversion(parseFloat(value), 2)}`
                : formatNumber(parseFloat(value), 1),
              prefix + transformLabel(name, dashboardMode === "general"),
            ];
          }}
        />

        {dataKeys &&
          dataKeys.map((key, index) =>
            key.includes("roas") ? null : (
              <>
                <Line
                  type="monotone"
                  yAxisId="1"
                  dataKey={key}
                  key={key}
                  strokeWidth={2}
                  stroke={colors[key]}
                  fillOpacity={1}
                  dot={false}
                />
                {compared ? (
                  <Line
                    type="monotone"
                    yAxisId="1"
                    dataKey={`compared_${key}`}
                    stroke={colors[key]}
                    dot={false}
                    strokeDasharray={"5 5"}
                    fillOpacity={1}
                    strokeWidth={2}
                  />
                ) : null}
              </>
            )
          )}
        <Line
          type="monotone"
          yAxisId="2"
          dataKey="roas"
          stroke="#DAA702"
          dot={false}
          strokeWidth={2}
        />
        {compared ? (
          <Line
            type="monotone"
            yAxisId="2"
            dataKey="compared_roas"
            stroke="#DAA702"
            dot={false}
            strokeDasharray={"5 5"}
            strokeWidth={2}
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
